import { app } from '@getpopsure/private-constants';
import {
  Button,
  Card,
  FullScreenModal,
  useMediaQuery,
  UserIcon,
} from '@popsure/dirty-swan';
import LinkTo from 'components/linkTo';
import {
  HiringBadge,
  LanguageSwitcher,
} from 'components/navigationBar/components';
import { type Locale } from 'constants/i18n';
import {
  type Subdomain,
  BUSINESS_SUBDOMAIN,
  DEFAULT_SUBDOMAIN,
} from 'constants/subdomains';
import { advice, company, resourcesMain } from 'content/navigation/links';
import Image, { StaticImageData } from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { forwardRef, ReactNode, Ref, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { getMenuByLocaleAndSubdomain } from 'util/getMenuByLocaleAndSubdomain';
import { getSiteUrl } from 'util/getSiteUrl';
import { isEnDeNonBusinessPage } from 'util/isEnDeNonBusinessPage';
import isExternalUrl from 'util/isExternalUrl';

import styles from './style.module.scss';

const SHADOW_PERCENT_SCROLL_SHOW_THRESHOLD = 5;

export default forwardRef(
  (
    {
      menu,
      isHiring,
      locale,
      subdomain,
    }: {
      menu: NavigationProductCategory[];
      isHiring: boolean;
      locale: Locale;
      subdomain: Subdomain;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const { push } = useRouter();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [openSection, setOpenSection] = useState('');
    const [showShadow, setShowShadow] = useState(false);
    const isDesktop = useMediaQuery('ABOVE_DESKTOP');

    const { t } = useTranslation();

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    useEffect(() => {
      if (isDesktop) {
        setMenuIsOpen(false);
      }
    }, [isDesktop]);

    const handleScroll = () => {
      const percentScrolled =
        (window.pageYOffset / window.screen.availHeight) * 100;
      setShowShadow(percentScrolled > SHADOW_PERCENT_SCROLL_SHOW_THRESHOLD);
    };

    const navigateTo = (link: string) => {
      setMenuIsOpen(false);
      isExternalUrl(link) ? (window.location.href = link) : push(link);
    };

    const Dropdown = ({
      title,
      children,
    }: {
      title: string;
      children: ReactNode;
    }) => (
      <>
        <button
          onClick={() => {
            setOpenSection(openSection === title ? '' : title);
          }}
          className={`ds-interactive-component fw-bold ${styles.cell} ${
            styles.cellProduct
          } ${openSection === title ? styles.cellProductOpen : ''}`}
          type="button"
        >
          {title}
        </button>
        <AnimateHeight
          duration={300}
          height={openSection === title ? 'auto' : 0}
        >
          <div className={`px16 ${styles.dropdownChildren}`}>{children}</div>
        </AnimateHeight>
      </>
    );

    const DropdownItem = ({ name, url, decorator }: NavigationLink) => (
      <>
        {decorator === 'SEPARATOR' && (
          <hr className={`${styles.separator} mx16 my24`} />
        )}
        <button
          className={`w100 ${styles.product}`}
          onClick={() => navigateTo(url)}
          type="button"
          key={name}
        >
          {name}
          {decorator === 'HIRING_BADGE' && isHiring && <HiringBadge />}
        </button>
      </>
    );

    return (
      <>
        <div
          ref={ref}
          className={`${styles.container} ${
            showShadow ? styles.containerShadow : ''
          }`}
        >
          <LinkTo href="/" aria-label={t('component.navigation.logo.aria')}>
            <div className={styles.logo} />
          </LinkTo>
          <button
            onClick={() => push(`${app.base}/account/signin`)}
            type="button"
            className={`${styles.button} ${styles.signinButton}`}
            aria-label={t('component.navigation.login.aria', 'Log in')}
          >
            <UserIcon
              size={24}
              className={styles.signinIcon}
              color="purple-500"
            />
          </button>
          <button
            onClick={() => {
              setMenuIsOpen(!menuIsOpen);
            }}
            type="button"
            className={`${styles.button} ${styles.hamburgerButton}`}
            aria-label="Menu"
          />
        </div>

        <FullScreenModal
          isOpen={menuIsOpen}
          onClose={() => setMenuIsOpen(false)}
          classNames={{
            title: 'pl8',
            closeButtonIcon: styles.closeButtonIcon,
            closeButton: styles.closeButton,
          }}
        >
          <div className={`pt24 pl16 ${styles.languageSwitcherWrapper}`}>
            {menuIsOpen && subdomain === DEFAULT_SUBDOMAIN && (
              <LanguageSwitcher
                renderInPortal
                locale={locale}
                subdomain={subdomain}
              />
            )}
          </div>
          <div className={styles.table}>
            <Dropdown title={t('component.navigation.product')}>
              {menu.length > 1 ? (
                <>
                  {menu.map((category) => (
                    <React.Fragment key={category.title}>
                      <CategoryTitle
                        title={category.title}
                        icon={category.icon}
                      />
                      <div className={`pb16 ${styles.products}`}>
                        {category.products.map(({ name, url, decorator }) => (
                          <DropdownItem
                            key={name}
                            name={name}
                            decorator={decorator}
                            url={url}
                          />
                        ))}
                      </div>
                    </React.Fragment>
                  ))}
                  <Card
                    as={LinkTo}
                    href={getSiteUrl(BUSINESS_SUBDOMAIN)}
                    title={t(
                      'component.navigation.product.business.title',
                      'Company insurance'
                    )}
                    description={t(
                      'component.navigation.product.business.description',
                      'Extra coverage as a benefit for employees.'
                    )}
                    density="compact"
                    classNames={{
                      wrapper: 'bg-primary-100',
                      actionIcon: 'tc-grey-700',
                    }}
                  />
                </>
              ) : (
                <>
                  {menu[0].products.map(({ name, url, decorator }) => (
                    <DropdownItem
                      key={name}
                      name={name}
                      url={url}
                      decorator={decorator}
                    />
                  ))}
                </>
              )}
            </Dropdown>
            {isEnDeNonBusinessPage(locale, subdomain) && (
              <Dropdown title={t('component.navigation.advice')}>
                {advice(t).map(({ name, url, decorator }) => (
                  <DropdownItem
                    key={name}
                    name={name}
                    url={url}
                    decorator={decorator}
                  />
                ))}
              </Dropdown>
            )}
            <Dropdown title={t('component.navigation.company')}>
              {getMenuByLocaleAndSubdomain(company(t), locale, subdomain).map(
                ({ name, url, decorator }) => (
                  <DropdownItem
                    key={name}
                    name={name}
                    url={url}
                    decorator={decorator}
                  />
                )
              )}
            </Dropdown>
            {getMenuByLocaleAndSubdomain(
              resourcesMain(t),
              locale,
              subdomain
            ).map(({ url, name }) => (
              <LinkTo
                className={`fw-bold ${styles.cell}`}
                href={url}
                key={name}
              >
                {name}
              </LinkTo>
            ))}
          </div>
          {subdomain === DEFAULT_SUBDOMAIN && (
            <div className="py24 p16">
              <Button
                leftIcon={<UserIcon />}
                className="w100"
                variant="filledGray"
                onClick={() => push(`${app.base}/account/signin`)}
              >
                {t('component.navigation.account.link', 'My account')}
              </Button>
            </div>
          )}
        </FullScreenModal>
      </>
    );
  }
);

const CategoryTitle = ({
  title,
  icon,
}: {
  title: string;
  icon: string | StaticImageData;
}) => (
  <div className="p16 br8 bg-grey-100 d-flex ai-center">
    <Image className="br8" src={icon} alt={title} width={48} height={48} />
    <div className={`tc-grey-600 fw-bold ${styles.categoryTitle}`}>{title}</div>
  </div>
);
