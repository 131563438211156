import {
  Alpha2CountryCode,
  countryOrRegionNameFromAlphaCode,
  mapCountryOrRegionFlag,
  Region,
} from '@getpopsure/public-models';

import { type Subdomain, BUSINESS_SUBDOMAIN } from './subdomains';

interface Flag {
  id: Region;
  label: string;
  icon: string;
  iconAlt: string;
}

export const flags = ({ subdomain }: { subdomain: Subdomain }): Flag[] => {
  const regionsForFlag = (
    subdomain === BUSINESS_SUBDOMAIN
      ? ['de']
      : ['de', 'es', 'fr', 'at', 'be', 'it', 'mt', 'nl', 'se', 'pt']
  ) as Partial<Region>[];

  return regionsForFlag.map((r) => {
    const codeUpperCased = r.toUpperCase() as Alpha2CountryCode;
    return {
      id: r,
      label: countryOrRegionNameFromAlphaCode(codeUpperCased),
      icon: mapCountryOrRegionFlag[codeUpperCased],
      iconAlt: `flag of ${countryOrRegionNameFromAlphaCode(codeUpperCased)}`,
    };
  });
};
