import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

// Europe/Berlin is our reference timezone. Strapi assumes date inputs are in the browser's local timezone and stores them in UTC format.
// To display the date in the UI, we need to convert it back to Europe/Berlin time.
// https://github.com/strapi/strapi/issues/18858#issuecomment-1821793187

export const convertToBerlinTime = (date: string | Date) => {
  const convertedDate = dayjs(date).tz('Europe/Berlin').format();

  if (convertedDate === 'Invalid Date') {
    throw new Error('Failed to convert the provided date to Berlin timezone');
  }

  return convertedDate;
};
