import { type Locale } from 'constants/i18n';
import { type Subdomain } from 'constants/subdomains';
import React, { Ref } from 'react';

import Desktop from './desktop';
import Mobile from './mobile';
import styles from './style.module.scss';

interface NavigationBarProps {
  allRefs?: {
    desktopNavRef: Ref<HTMLDivElement>;
    mobileNavRef: Ref<HTMLDivElement>;
  };
  menu: NavigationProductCategory[];
  isHiring: boolean;
  locale: Locale;
  subdomain: Subdomain;
}

const NavigationBar = ({
  allRefs,
  menu,
  isHiring,
  locale,
  subdomain,
}: NavigationBarProps) => {
  return (
    <nav role="navigation">
      <div className={styles.desktop}>
        <Desktop
          ref={allRefs?.desktopNavRef}
          menu={menu}
          isHiring={isHiring}
          locale={locale}
          subdomain={subdomain}
        />
      </div>
      <div className={styles.mobile}>
        <Mobile
          ref={allRefs?.mobileNavRef}
          menu={menu}
          isHiring={isHiring}
          locale={locale}
          subdomain={subdomain}
        />
      </div>
    </nav>
  );
};

export default NavigationBar;
