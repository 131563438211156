import {
  FULLY_SUPPORTED_LOCALES,
  Locale,
  PRISMIC_LOCALES,
} from 'constants/i18n';
import locales from 'locales';

export const isValidLocale = (value: string): value is Locale =>
  locales.includes(value as Locale);

export const isFullySupportedLocale = (value: string): value is Locale =>
  FULLY_SUPPORTED_LOCALES.includes(value as Locale);

export const isPrismicSupportedLocale = (value: string): value is Locale =>
  PRISMIC_LOCALES.includes(value as Locale);

// converts locale in path and Prismic en-de to i18next format en-DE
export const i18nLocaleFormatter = (locale?: string) => {
  if (!locale || !isValidLocale(locale)) return;
  const localeArr = locale.split('-');
  return `${localeArr[0]}-${localeArr[1].toUpperCase()}`;
};

export const validateLocale = (locale?: unknown): Locale => {
  if (!locale) throw Error('locale is undefined');
  if (typeof locale !== 'string') throw Error('locale is not a string');
  if (!isValidLocale(locale)) throw Error('locale is not valid');
  return locale;
};
