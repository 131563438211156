import { website } from '@getpopsure/private-constants';
import { ArrowUpRightIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import LinkTo from 'components/linkTo';
import { EventJsonLd } from 'next-seo';
import StrapiRichTextComponent from 'strapi/components/StrapiRichText';
import { StrapiAnnouncementBarData } from 'strapi/types/utils';
import { convertToBerlinTime } from 'strapi/utils/convertToBerlinTime';

import styles from './AnnouncementBar.module.scss';

export const AnnouncementBar = ({
  message,
  cta_link,
  cta_text,
  eventDetails,
}: StrapiAnnouncementBarData) => {
  return (
    <section
      className={classNames(
        'd-flex f-wrap bg-purple-100 p16 ai-center jc-center p-p',
        styles.message
      )}
    >
      {eventDetails && (
        <EventJsonLd
          name={eventDetails.name}
          url={eventDetails.url}
          startDate={convertToBerlinTime(eventDetails.startDate)}
          endDate={convertToBerlinTime(eventDetails.endDate)}
          location={{ url: eventDetails.url }}
          description={eventDetails.description}
          organizer={{
            type: 'Organization',
            name: 'Feather Insurance',
            url: website.base,
          }}
          eventAttendanceMode="OnlineEventAttendanceMode"
        />
      )}
      {message && (
        <StrapiRichTextComponent richText={message} paragraphClassName="p-p" />
      )}
      {cta_link && cta_text && (
        <LinkTo
          href={cta_link.url}
          target={cta_link.target}
          className="p-a fw-bold tc-grey-900 ml8 d-flex"
        >
          {cta_text}
          <ArrowUpRightIcon size={16} className="ml8" />
        </LinkTo>
      )}
    </section>
  );
};
